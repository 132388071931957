import getDay from "date-fns/getDay";
import { baseName } from "../config/ApiConstants";

// Regex
export const removeWhiteSpace = /^\S/;
export const aplhaSpecial = /^[A-Z@~`!@#$%^&*()_ =+\\\\';:\"\\/?>.<,-]*$/i;
export const alphaOnly = /^[A-Z@]*$/i;
export const phoneRegExp = /^[0-9]{11}$/;
export const passwordRegex = /^(?=.*\d).{6,}$/;
export const emailPhoneRegex =
  /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})|([0-9]{10})+$/;
export const numericOnly = /^[0-9]+$/i;
export const specialCharacters = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;

// Alert message types
export const alrtTypes = {
  WARNING: "warning",
  SUCCESS: "success",
};

const LOSE_WEIGHT = "Lose weight",
  GAIN_WEIGHT = "Gain weight",
  MAINTAIN_WEIGHT = "Maintain weight";

export const fitnessGoals = [
  {
    head: LOSE_WEIGHT,
    subhead: "My main goal is to shed the extra weight in a healthy way.",
    headAr: "نول الون",
    subheadAr: "هدفي الرئيسي هو أن أنزل وزني بطريقة صحية",
    icon: "icon-lose",
  },
  {
    head: GAIN_WEIGHT,
    subhead:
      "My goal is to increase my weight in a controlled and healthy way.",
    headAr: "زيادة الوزن",
    subheadAr: "هدفي الرئيسي هو ان ازيد وزني بطريقة محكومة وصحية",
    icon: "icon-gain",
  },
  {
    head: MAINTAIN_WEIGHT,
    subhead:
      "My main goal is to maintain my current weight and to stay healthy.",
    headAr: "الحفاظ على الون",
    subheadAr: "هدفي الرئيسي هو الحفاظ على وزني الحالي",
    icon: "icon-maintain",
  },
];

export const genderArray = ["Male", "Female"];
export const localeGenderArray = [
  {
    gender: "Male",
    genderAr: "ذكر",
    label: "Male",
  },
  {
    gender: "Female",
    genderAr: "أنثى",
    label: "Female",
  },
];

export const activities = [
  {
    class: "exercise",
    content: "I barely exercise or don't at all",
    contentAr: "اتمرن قليلا أو لا أتمرن",
    adultValue: 1.05,
    youthValue: 1,
  },
  {
    class: "workoutsone",
    content: "I exercise 1 to 3 times a week",
    contentAr: "أتمرن ما بين ١ إلى ٣ مرات اسبوعياً",
    adultValue: 1.08,
    youthValue: 1.01,
  },
  {
    class: "workoutstwo",
    content: "I exercise 4 to 6 times a week",
    contentAr: "أتمرن ما بين ٤ إلى ٦ مرات اسبوعياً",
    adultValue: 1.1,
    youthValue: 1.03,
  },
  {
    class: "intense",
    content: "I do daily intense workouts",
    contentAr: "التمارين اليومية أو التدريبات المكثفة",
    adultValue: 1.12,
    youthValue: 1.05,
  },
];

export const onFocus = (ref) => {
  ref?.current?.focus();
};

// Calculation

export const userTargets = [LOSE_WEIGHT, GAIN_WEIGHT, MAINTAIN_WEIGHT];

export const loseWeight = (bmr, avtivity) => {
  return bmr * avtivity - 500;
};

export const gainWeight = (bmr, avtivity) => {
  return bmr * avtivity + 500;
};

// BMI
// BMI = Wt (kg) / H (m) ^2
const femaleBMI = (weight, height) => {
  return weight / (height * height);
};

// YOUTH (<15 YRS) MALE AND FEMALE WEIGHT LOSS USE YOUTH CALC ;
// BMR = 114 - (50.9 x Age) + 1 x (19.5 x Weight kg + 1161.4 x Height m )
export const youthMale = (age, weight, height) => {
  const val = 114 - 50.9 * age + 1 * (19.5 * weight + 1161.4 * (height / 100));
  return val;
};

// BMR = 389 - (41.2 x Age) + 1 x (15 x Weight kg + 701.6 x Height m)
export const youthFemale = (age, weight, height) => {
  const val = 389 - 41.2 * age + 1 * (15 * weight + 701.6 * (height / 100));
  return val;
};

// ADULT (>15 YRS) MALE AND FEMALE WEIGHT LOSS USE MIFLIN  AND HARRIS CALC ;
// BMR = 10W + 6.25H - 5A + 5
export const adultMale = (age, weight, height) => {
  const val = 10 * weight + 6.25 * height - 5 * age + 5;
  return val;
};

// ADULT LOSE WEIGHT
// BMR = 10W + 6.25H - 5A - 161
export const adultFemale = (age, weight, height) => {
  // check BMI
  const bmi = femaleBMI(weight, height / 100);
  if (bmi < 29.9) {
    // BMR = 9.247W + 3.098H - 4.330A + 447.593
    const val = 9.247 * weight + 3.098 * height - 4.33 * age + 447.593;
    return val;
  } else {
    // BMR = 10W + 6.25H - 5A - 161
    const val = 10 * weight + 6.25 * height - 5 * age - 161;
    return val;
  }
};

// ADULT GAIN WEIGHT

// Male
// BMR = 13.397W + 4.799H - 5.677A + 88.362
export const adultMaleGain = (age, weight, height) => {
  const val = 13.397 * weight + 4.799 * height - 5.677 * age + 88.362;
  return val;
};

// Female
// BMR = 9.247W + 3.098H - 4.330A + 447.593
export const adultFemaleGain = (age, weight, height) => {
  // // Don't need check BMI
  const val = 9.247 * weight + 3.098 * height - 4.33 * age + 447.593;
  return val;
};

// RoundUp with near 10s
export const roundDownToNearest10 = (num) => {
  const cal = Math.ceil(num / 10) * 10;
  if (cal <= 1000) return 1000;
  else return cal;
};

export const roundDownToNearest10Two = (num) => {
  const cal = Math.ceil(num / 10) * 10;
  return cal;
};

export const toFixed = (val) => {
  if (val == 0) return 0;
  else return val;
};

// Date Convertion
export const dateConvert = (str) => {
  var date = new Date(str),
    mnth = ("0" + (date.getMonth() + 1)).slice(-2),
    day = ("0" + date.getDate()).slice(-2);
  return [date.getFullYear(), mnth, day].join("-");
};

// Percantage
export const findPercentage = (value, totalValue, percentage) => {
  const val = (value * totalValue) / percentage;
  return val.toFixed(3);
};

export const findFinalAmount = (actualvalue, discountValue) => {
  const val = (actualvalue - discountValue).toFixed(3);
  return val;
};

// getFridaysCount
export const getFridaysCount = (startDate, endDate) => {
  var totalWeekends = 0;
  for (
    var i = new Date(startDate);
    i <= new Date(endDate);
    i.setDate(i.getDate() + 1)
  ) {
    if (i.getDay() == 5) totalWeekends++;
  }
  return totalWeekends;
};

export const fridayFilter = (date) => {
  const day = getDay(date);
  return day !== 5;
};

export const fridayAndSaturdayFilter = (date) => {
  const day = getDay(date);
  return day !== 5 && day !== 6;
};

export const weekDays = [
  { value: 1, label: "menuReview.mon" },
  { value: 2, label: "menuReview.tue" },
  { value: 3, label: "menuReview.wed" },
  { value: 4, label: "menuReview.thu" },
  { value: 5, label: "menuReview.fri" },
  { value: 6, label: "menuReview.sat" },
  { value: 7, label: "menuReview.sun" },
];

export const twoWeeks = [
  { value: "week1", label: "menuReview.week1" },
  { value: "week2", label: "menuReview.week2" },
];

// Here, Adding one more day in lock days, (After 10 AM of kuwait time)
function convertTZ(date, tzString) {
  return new Date(
    (typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", {
      timeZone: tzString,
    })
  );
}

export const isAfterTen = () => {
  var kuwaitDay = convertTZ(new Date(), "Asia/Kuwait");
  var kuwaitDay2 = convertTZ(new Date(), "Asia/Kuwait");
  kuwaitDay.setHours(10);
  kuwaitDay.setMinutes(0);
  kuwaitDay.setMilliseconds(0);
  return new Date(kuwaitDay2).getTime() > kuwaitDay.getTime();
};

export const getmealTypeIconClasses = (type) => {
  switch (type) {
    case "Breakfast":
    case "وجبات الافطار":
      return "icon-breakfast";
    case "Main Meals & Sandwiches":
    case "الوجبات الرئيسية والسندويتشات":
      return "icon-meal";
    case "Snacks & Salads":
    case "السناكات والسلطات":
      return "icon-snacks";
    case "Fruits & Desserts":
    case "الحلويات والفواكه ":
      return "icon-fruits";
  }
};

// messages
export const exceededLimit = "dashboard.exceededLimit";
export const pauseText = "dashboard.pauseTxt";
export const pauseHead = "dashboard.pauseHead";
export const unpauseTxt = "dashboard.unpauseTxt";
export const unpauseHead = "dashboard.unpauseHead";

//
export const metaDescriptions = {
  28: "Anona Diet meal plan, helps you to gain weight, ranging from 1000 to 2600 calories with 1 Breakfast, 2 main meals & sandwiches, 2 snacks & salads and 1 fruit & dessert.",
  24: "Are you a late eater? No worries, we have got you a healthy diet meal plan ranging from 1000 to 1800 calories high quality and tasty diet food.",
  26: "Looking for a perfect diet plan for weight loss? Then subscribe to Anona monthly diet meal plans and have fun with our tasty diet food.",
  40: "Our low calorie meal plan helps you to lose those extra pounds and makes you look fit. Subscribe now and enjoy you meal!",
  "menu-review":
    "View Anona Diet menu online and choose your favorite dishes from our wide variety of tasty and high quality food options. Enjoy your meal!",
  "plan-details":
    "Anona Diet meal plan, helps you to reach your weight goals, ranging from 500 to 2600 calories with Breakfast, main meals & sandwiches, snacks & salads and  fruit &  dessert.",
  anona:
    "Diet Food is never boring with Anona! Subscribe to our wide variety of healthy meal plans online and get consultation from our professional dietitians",
};

export const metaTitles = {
  28: "Anona Diet Meal|Choose from 1000 to 2600 calorie Meal Plans",
  24: "Healthy Diet Plan For Weight Loss|1000 to 1800 Calorie Diet Plan",
  26: "Healthy Diet Plan For Weight Loss|1000 to 1800 Calorie Diet PlanPerfect Diet Plan For Weight Loss|Healthy Meal Plan Subscription",
  40: "Low Calorie Meal Plan | 500 to 1200 Calorie Diet Plan",
  "menu-review": "View AnonaDiet Meal Plan Menu Online|Healthy Diet Plan Menu",
  "plan-details": "Anona Diet Meal|Choose from 500 to 2600 calorie Meal Plans",
  anona: "Diet Food Kuwait |Subscribe to Diet Meal Plans Online|Anona",
};

export const isDecimelOrZero = (val) => {
  let valToNumber = parseFloat(val);
  if (valToNumber) {
    if (valToNumber % 1 == 0) return valToNumber;
    else return valToNumber.toFixed(1);
  } else {
    return 0;
  }
};

export const chatIconRoutes = [baseName];

export const onLogout = () => {
  const lang_id = localStorage.getItem("lang_id"),
    lang = localStorage.getItem("lang");
  localStorage.clear();

  localStorage.setItem("lang", lang);
  localStorage.setItem("lang_id", lang_id);
};

export const dateFormat = {
  DDMMYYYY: "DD-MM-YYYY",
  MMDDYYYY: "MM-DD-YYYY",
};

export const _weekdays = {
  mon: "mon",
  tue: "tue",
  wed: "wed",
  thu: "thu",
  fri: "fri",
  sat: "sat",
  sun: "sun",
};

export const _toLowerCase = (val) => {
  return val.toLowerCase();
};

export const rediectToAppStore = () => {
  const isAndroidDevice = /Android/i.test(navigator.userAgent);
  isAndroidDevice ? window.open("https://play.google.com/store/apps/details?id=com.lifestyle.anona.diet", "_blank") : window.open("https://apps.apple.com/us/app/anona-diet/id1661103386", "_blank");
}
