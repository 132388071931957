import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { PATHS } from "../constants/Paths";

const Home = React.lazy(() => import('../pages/Home'));
const FAQ = React.lazy(() => import('../pages/Faq'));
const ResetPassword = React.lazy(() => import('../components/Modal/ResetPassword'));
const PasswordChanged = React.lazy(() => import('../components/Modal/PasswordChange'));
const TermsAndConditions = React.lazy(() => import('../pages/TermsConditions'));
const PrivacyPolicy = React.lazy(() => import('../pages/PrivacyPolicy'));
const CalculateCalorie = React.lazy(() => import('../pages/CalculateCalorie'));
const CalculateResult = React.lazy(() => import('../pages/CalorieResult/resultcalculate'));
const CalorieSelect = React.lazy(() => import('../pages/CalorieResult/calorieselect'));
const Subscription = React.lazy(() => import('../pages/Subscription'));
const PaymentSuccess = React.lazy(() => import('../pages/Payment/payment-success'));
const PaymentFailure = React.lazy(() => import('../pages/Payment/payment-fail'));
const AuthLayout = React.lazy(() => import("../components/AuthLayout"));
const PlanDetails = React.lazy(() => import("../pages/PlanDetails"));
const MenuReview = React.lazy(() => import("../pages/MenuReview/menu-review"));
// https://anona-diet-dev.qburst.build/lifestyle-revamp/payment-failure".$returnvals."?id=" . $returnId . "&msg=" . $ResponseMessage."&Result=NOTCAPTURED&=ReferenceNumber".$ReferenceNumber



// Succs
// https://anona-diet-dev.qburst.build/lifestyle-revamp/payment-success/?Result=PAID&ReferenceNumber=2022000017&msg=Transaction%20(%20of%20Day%20Extend%20)%20Successfull.&PayTxnID=100202228997761879&udf1=7360&udf2=7.120%20KD&Paymode=KNET&package_name=DaysExtend&CustomerEmail=arun@gmail.com&Amount=7.120%20KD
// const AuthRoutes = () => {
//     const location = useLocation();
//     return (
//         <Navigate to={PATHS.HOME} state={{ path: location.pathname }} />
//     );
// }


const routesArray = [
    { path: PATHS.APP_INIT, element: <Home /> },
    { path: PATHS.HOME, element: <Home /> },
    { path: PATHS.FAQ, element: <FAQ /> },
    { path: PATHS.RESET_PASSWORD, element: <RedirectToApp /> },
    { path: PATHS.PASSWORD_CHANGED, element: <RedirectToApp /> },
    { path: PATHS.TERMS_AND_CONDITIONS, element: <TermsAndConditions /> },
    { path: PATHS.PRIVACY_POLICY, element: <PrivacyPolicy /> },
    { path: PATHS.CALCULATE_CALORIE, element: <RedirectToApp /> },
    { path: PATHS.CALCULATE_RESULT, element: <RedirectToApp /> },
    { path: PATHS.CALCULATE_RESULT1, element: <RedirectToApp /> },
    { path: PATHS.CALORIE_SELECT, element: <RedirectToApp /> },
    { path: PATHS.SUBSCRIPTION, element: <RedirectToApp /> },
    { path: PATHS.SUBSCRIPTION2, element: <RedirectToApp /> },
    { path: PATHS.PAYMENT_SUCCESS, element: <RedirectToApp /> },
    { path: PATHS.PAYMENT_SUCCESS2, element: <RedirectToApp /> },
    { path: PATHS.PAYMENT_FAIL, element: <RedirectToApp /> },
    { path: PATHS.PAYMENT_FAIL_ONLY, element: <RedirectToApp /> },
    { path: PATHS.AUTH_LAYOUT, element: <RedirectToApp /> },
    { path: PATHS.FORGOT_PASSWORD_REDIRECT_LINK, element: <RedirectToApp /> },
    { path: PATHS.PLAN_DETAILS, element: <PlanDetails /> },
    { path: PATHS.MENU_REVIEW, element: <MenuReview /> }
];

function RedirectToApp() {
    React.useEffect(() => {
        const isAndroidDevice = /Android/i.test(navigator.userAgent);
        const url = isAndroidDevice ? "https://play.google.com/store/apps/details?id=com.lifestyle.anona.diet" : "https://apps.apple.com/us/app/anona-diet/id1661103386";
        window.open(url, '_blank');
    }, []);

    // Return null because this component only handles the redirection
    return null;
}

export default routesArray;

